<template>
  <div class="w-full flex">
    <div class="w-1/4">
      <vx-card title="Colaboradores">
        <div class="w-full" v-for="i in 20" v-bind:key="i">
          <vs-list-item title="Steve Jobes" subtitle="col@emops.com.br">
            <template slot="avatar">
              <vs-avatar />
            </template>
            <vs-button icon="keyboard_arrow_right" size="small" style="color: black;">Metas</vs-button>
          </vs-list-item>
        </div>
      </vx-card>
    </div>
    <div class="w-3/4">
      <vs-row vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12" class="ml-2">
        <vs-col vs-w="4" class="pr-2">
          <vx-card title="Vendas">
            <vue-apex-charts
              type="radialBar"
              height="350"
              :options="radialBarChart.chartOptions"
              :series="radialBarChart.series"
              width="300"
            ></vue-apex-charts>
          </vx-card>
        </vs-col>
        <vs-col vs-w="4" class="pr-2">
          <vx-card title="Vendas">
            <vue-apex-charts
              type="radialBar"
              height="350"
              :options="radialBarChart.chartOptions"
              :series="radialBarChart.series"
              width="300"
            ></vue-apex-charts>
          </vx-card>
        </vs-col>
        <vs-col vs-w="4">
          <vx-card title="Vendas">
            <vue-apex-charts
              type="radialBar"
              height="350"
              :options="radialBarChart.chartOptions"
              :series="radialBarChart.series"
              width="300"
            ></vue-apex-charts>
          </vx-card>
        </vs-col>
      </vs-row>
       <vs-row vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12" class="ml-2 mt-4">
        <vs-col vs-w="4" class="pr-2">
          <vx-card title="Vendas">
            <vue-apex-charts
              type="radialBar"
              height="350"
              :options="radialBarChart.chartOptions"
              :series="radialBarChart.series"
              width="300"
            ></vue-apex-charts>
          </vx-card>
        </vs-col>
        <vs-col vs-w="4" class="pr-2">
          <vx-card title="Vendas">
            <vue-apex-charts
              type="radialBar"
              height="350"
              :options="radialBarChart.chartOptions"
              :series="radialBarChart.series"
              width="300"
            ></vue-apex-charts>
          </vx-card>
        </vs-col>
        <vs-col vs-w="4">
          <vx-card title="Vendas">
            <vue-apex-charts
              type="radialBar"
              height="350"
              :options="radialBarChart.chartOptions"
              :series="radialBarChart.series"
              width="300"
            ></vue-apex-charts>
          </vx-card>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  data() {
    return {
      themeColors: ["#7367F0", "#28C76F", "#EA5455", "#FF9F43", "#1E1E1E"],
      radialBarChart: {
        series: [44, 55, 67, 83],
        chartOptions: {
          colors: this.themeColors,
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "22px"
                },
                value: {
                  fontSize: "16px"
                },
                total: {
                  show: true,
                  label: "Total",
                  formatter: function(w) {
                    w = 249// By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                    return w;
                  }
                }
              }
            }
          },
          labels: ["Apples", "Oranges", "Bananas", "Berries"]
        }
      },
      pieChart: {
        series: [44, 55, 13, 43],
        chartOptions: {
          labels: ["Team A", "Team B", "Team C", "Team D"],
          colors: this.themeColors,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ]
        }
      },
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 500
      },
      lineChartSimple: {
        series: [{
          name: "Desktops",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        }],
        chartOptions: {
          chart: {
            height: 350,
            zoom: {
              enabled: false
            }
          },
          colors: themeColors,
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight'
          },
          title: {
            text: 'Product Trends by Month',
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
          }
        }
      }
    };
  },
  methods: {},
  components: {
    VueApexCharts,
    VuePerfectScrollbar
  },
  mounted() {}
};
</script>

<style>
.title-card {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: #2c2c2c;
  font-size: 18.5px;
}
.scroll-area--data-list-add-new {
  width: 100%;
  height: calc(100%);
  position: relative;
  margin: auto;
}
</style>
